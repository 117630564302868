#messageArea, #messageArea-manageCalls {
    padding: 2%;
    overflow-y: auto;
    flex: 1;
}

#messageArea ul {
    padding:0
}

/* #messageArea-manageCalls {
    max-height: 60vh;
} */

.message-item {
    display: flow-root;
}

.supervisor-action {
    float: right;
    margin-bottom: 7px;
}

.media-status {
    float: right;
    border-radius: 5px 0 5px 5px;
    padding: 5px 10px;
    color: white;
    font-style: italic;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 90%;
    font-weight: 500;
}

.media-status.media-pending {
    color: #ffc107;
}

.media-status.media-rejected {
    color: #db5353;
}

.media-status.media-approved {
    color: #53db60;
}


.scrolldown-box{
    position: absolute;
    bottom: 5rem;
    right: 50%;
    width: 3rem;
    height: 3rem;
    border-radius: .25rem;
    background-color: rgb(245, 245, 245);
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 6px 1px #b7b7b7;
}

.scrolldown-box:hover{
    background-color:#dadada;
    cursor:pointer
}

.messageAmount-badge{
    width: 1rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
    color: white;
    font-weight: bold;
    background-color: #53db60;
    font-size: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}