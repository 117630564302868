.myteam-page {
	display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.myteam-page .page-title {
	font-size: 1.5em;
    font-weight: bold;
    color: #21446c;
    margin: 1rem 0;
}

.myteam-page .myteam-settings {
	padding: 0 1rem;
	min-width: 33.5rem;
}

.myteam-page .myteam-settings .team-members {
	max-width: 33.5rem;
	margin-bottom: 2rem;
}

.myteam-page .myteam-events {
    border: 1px solid #ebebeb;
    flex-grow: 1;
    margin: 1rem;
    border-radius: 5px;
    padding: 1rem;
}

.myteam-page .myteam-events .events-actions {
	position: absolute;
	right: 2rem;
}

.myteam-page .myteam-events button {
	display: flex;
	gap: .5rem;
}

.myteam-page .myteam-events button svg {
    font-size: 20px;
}

.myteam-page .myteam-events .events-timeline {
	margin: 1rem 0 0 0;
	max-height: 80vh;
    overflow: auto;
}

.myteam-page .myteam-events .events-timeline li {
	min-height: 7.5rem;
}

.myteam-page .myteam-events .events-timeline li::before {
	content: none !important;
    display: none !important;
}

.myteam-page .myteam-events .events-timeline .event-description {
	color: #767676;
	margin-top: .5rem;
	text-align: justify;
}
