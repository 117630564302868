.assistant-entry-button{
    position: absolute;
    right: 125px;
    top: 14px;
    z-index: 1300;
}

.assistant-entry-button button{
    background-color:white;
    color:blueviolet

}

.assistant-entry-button button:active,
.assistant-entry-button button:hover{
    background-color:blueviolet;
    color:white
}

.assistant-icon{
    font-size:15pt;
    color:inherit
}
.gpt-dialog .gpt-input-area, 
#assistant-container-vpower .gpt-input-area{
    height:3.5rem;
}

#assistant-container-vpower .App{
    height:100%
}

#assistant-container-vpower .gpt-main-area-box{
    height:100%
}

#assistant-container-vpower{
    height:100%;
    position: relative;
    z-index: 1300;
    flex: 1
}

#assistant-container-vpower .gpt-chat-area{
    height:80%
}

#assistant-container-vpower .gpt-chat-area ul{
    max-height:100%
}

.gpt-child-info-container{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}