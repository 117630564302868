.contactprofile-mainframe {
    display: flex;
    height: 100%;
    gap:.5rem
}

.contactprofile-left{
    height: 100%;
    display: flex;
    flex: 0.4;
    max-width: 27.5rem;
    min-width: 20rem;
    background:rgb(253, 253, 253);
    border: 1px solid rgb(223, 223, 223);
    border-radius:.25rem;
    flex-direction: column;
    align-items: center;
    font-size:.8em;
    overflow: auto
}

.contactprofile-left .contact-name{
    margin-top: 1rem;
    font-size: 1.3em;
    font-weight: bold;
    cursor:pointer
}

.contactprofile-left .contact-channels{
    margin: 1rem 0;
    display: flex;
    gap: 1rem
}

.contactprofile-left .contact-channels > div{
    padding: .25rem;
    border: 1px solid rgb(223, 223, 223);
    
}

.contactprofile-left .avatarName-big{
    width:80px;
    height:80px;
    padding:15px;
    font-size: 35px;
}


.contactprofile-right {
    flex: 1;
    display: flex;
    background:rgb(253, 253, 253);
    border: 1px solid rgb(223, 223, 223);
    border-radius:.25rem;
    overflow:hidden
}

.contact-general-item-box {
    display: flex;
    gap: .25rem;
    align-items: center;
}

li.contact-general-listitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 1.3rem;
}

.contactprofile-mainframe .contact-items{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-general-data {
    max-width: 15rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.contact-general-data.placeholder{
    font-weight: 100;
    font-style: italic;
    cursor: pointer
}

.contact-general-field{
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.contact-general-field svg{
    font-size:1.5em;
    cursor: pointer
}

.contact-tasks,
.contact-description,
.contact-groups{
    position: relative;
    width: 100%;
    padding: .5rem 1.3rem;
}

.contact-tasks > div,
.contact-description > div,
.contact-groups > div{
    width:100%
}


.contact-description .MuiOutlinedInput-root{
    flex-direction: column;
}

button.contact-textarea-button{
    font-size: 0.7em;
    padding: 0.1rem;
    align-self: self-end;
}

.contact-groups .contact-groups-list{
    display: flex;
    margin-top: .5rem;
    flex-wrap: wrap;
    gap: .3rem 0.1rem;
    max-height: 4rem;
    overflow: auto;
}

.contact-groups .contact-groups-list > div{
    padding: .2rem .4rem;
    background-color: #ebebeb;
    color: black;
}

.custom-fields .flexrow,
.contact-groups .flexrow{
    display:flex;
    align-items: center;
}

.contact-general-item-box .align-icons{
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.contactprofile-left .custom-fields {
    width: 100%;
    margin-top: .5rem;
}
.contactprofile-left .custom-fields .field-label{
    padding: 0rem 0rem 0rem 1.25rem
}


.contactprofile-right .profiletab{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: .5rem;
}

.contactprofile-right .profilecomponent {
    overflow: auto;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    word-break: break-word;
}

.historic-show-more{
    display:flex;
    width: 100%;
    justify-content: center;
}

.historic-row-mainbox {
    display: flex;
    gap: 1rem;
}

.historic-row-title{
    font-weight: bold;
}

.historic-row-icon{
    display: flex;
    align-items: center;
    flex-direction: column;
    position:relative
}

.historic-row-icon-svg{
    width: 3rem;
    height: 3rem;
    background: gray;
    position: relative;
    z-index:1
}

.historic-list-gap{
    display: flex;
    flex-direction: column;
    gap: 1rem
}

span.historic-row-icon-svg {
    padding: 1rem;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.historic-row-mainbody {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.historic-row-header{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.historic-time-actions{
    display: flex;
    gap: .5rem;
    align-items: center;
}

.historic-row-details {
    min-height: 5rem;
    margin: 0.5rem 0.5rem 0rem 0rem;
    background: #f3f3f3;
    padding: 0.5rem;
    white-space: pre-line;
    overflow: auto;
    line-height: 1.4rem;
}

span.historic-row-segment-line {
    height: 100%;
    display: block;
    border-left: dashed;
    border-width: 2px;
    border-color: #e1e1e1;
    position: absolute;
    margin-top: 1.5rem;
}

.historic-row-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: .25rem;
    align-items: center;
}

.historic-row-mainbody .historic-row-actions button{
    font-size: .5em;
    padding: .5em;
    height: 1rem;
}

.contact-groups .groups-autocomplete{
    padding: 0.5rem 0rem
}

.vertical {
    /* max-height: 82.5dvh;
    max-height: 82.5vh;
    overflow: auto; */
}

.vertical .contactprofile-mainframe {
    flex-direction: column;
}
.vertical .contactprofile-left{
    padding-bottom: 1rem
}

.vertical .avatarName {
    display: none;
}

.vertical .contact-name {
    margin-top: 0;
}

.vertical .contactprofile-left,
.vertical .contactprofile-right {
    border: 0;
    background-color: white;
}

.vertical .contactprofile-left::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
}

.vertical .historic-row-details {
    font-size: .8rem;
}

.vertical .contactprofile-left {
    max-width: none;
    min-width: 0;
    overflow: visible;
}

.vertical .contactprofile-right .profilecomponent {
    overflow: visible;
}
