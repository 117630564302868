#conversationList-box-1 {
	width: 100%;
	height: 100%; 
}
#conversationList-box-2 {
	display: flex; 
	flex-direction: column;
	height: 100%;
}
#conversationList-box-3 {
	width: 100%;
}
#conversationList-box-4 {
	text-align: center; 
	margin-top: 50px;
}
#conversationList-box-5 {
	text-align: center; 
	margin-top: 50px;
	overflow:hidden
}
#conversationList-box-6 {
	text-align: center; 
	margin-top: 50px;
}
#conversationList-box-7 {
	text-align: center; 
	margin-top: 50px;
}
.tabs-conversation-list {
	height: 48px;
}
.tab-active {
	position: relative;
	height: 100%;
	flex:1;
	display:flex;
	overflow:hidden;
	flex-direction:column;
}


@media (min-width: 90rem) {
	.unreadQUEUE {
		left: 6%!important;
	}
	.unreadONCHAT {
		left: 29%!important;
	}
}

.badge-unreadMessages > span > span {
	background-color: #38a951;
	color: white;
	position: relative;
	margin-left: -8px;
}

.filter-finished-chats{
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.popover-filter > .MuiPopover-paper {
	padding: 10px;
}

.filters-buttons {
	text-align: center;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: -5px;
}

.filters-buttons.flex{
	display:flex;
	flex-direction: column;
	gap: .5rem;
	padding: .5rem .25rem
}

.filters-body, .filter-switch {
	border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px;
}

.date-start-time {
	margin-top: 15px;
}

.date-end-time {
	margin-top: 10px;
}

.filters-action {
	margin-top: 10px;
	text-align: end;
}

.button-discard {
	margin-right: 5px;
}

.switch-item {
	margin-bottom: -10px;
}

.switchs-filters {
	display: grid;
	padding: 0px 0px 3px 17px;
}

.title-filters {
	font-size: 13px;
    text-align: start;
    color: #21446c;
    font-weight: bold;
}

.buttons-group {
	margin-top: 5px;
	margin-bottom: 3px;
}

.tab {
	min-width: 25%;
	color: #0000008a;
}

.sub-tab {
	font-size: smaller;
	flex: 0.5;
	color: #0000008a;
}

.inactive-filter-icon {
	color: #757575;
}

.active-filter-icon {
	color: #21446c;
}

.refresh-finished-btn {
	color: #757575;
}

.load-more-container {
	text-align: center;
	margin-top: 10px;
}
