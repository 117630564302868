.relative-box {
  position:relative;
  display:flex;
  flex:1;
}

.relative-box button {
  position: absolute;
}

.relative-box .select-pop-up ul {
  text-align: left;
}

.select-pop-up-backdrop {
  display:none;
  position:fixed;
  top: 0px;
  left: 0px;
  width:100%;
  height: 100%;
}

.select-pop-up-backdrop.active {
  display:block
}

.select-list {
  padding: 0;
  background: white;
  box-shadow: 1px 1px 8px 1px #a1a1a1;
  border-radius: .25rem;
  z-index: 2;
  max-height: 20rem;
  overflow-y: auto;
}

.select-list.insiderlist {
  position:absolute;
  right:0
}

.select-list-item {
  padding: 1rem 1.5rem;
  z-index: 2;
  font-size: 12px;
  min-width: max-content;
}

.select-pop-up {
  display:none;
  position: fixed;
  left: 0px;
  top: 0px;
  background: transparent;
  z-index: 1;
  padding-top:1rem;
  padding-left:1rem;
  padding-right:1rem;
}

.select-pop-up.active{
  display:block
}

.absolute-button {
  position: absolute;
  top:0;
  right:0;
}
