.loading-container {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-container.nofixed{
    position: absolute;
    height: 100%;
}


.loading {
    background-color: #00000070;
    color: white;
    border-radius: 5px;
    padding: 1.5rem;

    z-index: 10000;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-weight: bold;
    font-family: unset;
}

#loading-grid {
	padding-top: 15px !important;
}

#refresh-icon {
	display: inline-table;
}

#refresh-icon > svg {
    color: white;
}
