.agenda-container {
	display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.agenda-container .agenda-timeline {
    padding: 1rem;
    min-width: 30rem;
    padding-right: 0;
}

.agenda-container .agenda-timeline .timeline-filters {
    display: flex;
    gap: .5rem;
    margin-bottom: 0.65rem;
}

.agenda-container .agenda-timeline .timeline-filters div {
    display: flex;
    flex-grow: 1;
}

.agenda-container .agenda-calendar {
    flex-grow: 1;
    border-radius: 5px;
    padding: 1rem;
}

.agenda-container .agenda-calendar .rbc-month-row {
    cursor: pointer;
}

.agenda-container .agenda-calendar .rbc-header {
    padding: .25rem;
}

.agenda-container .agenda-calendar .rbc-btn-group button {
    padding: .65rem 1rem;
}

.agenda-container .agenda-calendar .rbc-btn-group:nth-child(3) button:nth-child(2),
.agenda-container .agenda-calendar .rbc-btn-group:nth-child(3) button:nth-child(4) {
    display: none;
}

.agenda-container .agenda-calendar .rbc-month-view {
    border-radius: 5px;
}

.agenda-container .agenda-timeline .timeline {
    height: 78.75vh;
    overflow: auto;
}

.agenda-container .agenda-timeline .timeline .timeline-dot {
    background-color: white;
    box-shadow: none;
}

.agenda-container .agenda-timeline .timeline .timeline-content .timeline-content-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.agenda-container .agenda-timeline .timeline li {
    min-height: 8rem;
}

.agenda-container .agenda-timeline .timeline li::before {
    content: none !important;
    display: none !important;
}

.agenda-container .agenda-timeline .timeline .timeline-description {
    color: #767676;
    margin-top: .5rem;
    text-align: justify;
}

.agenda-container .agenda-timeline .timeline .timeline-description .timeline-contact-target {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.agenda-container .agenda-timeline .timeline .timeline-description .timeline-contact-target .timeline-contact-data,
.agenda-container .agenda-timeline .timeline .timeline-description .timeline-contact-target .timeline-target-data {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #333;
    margin-bottom: .25rem;
}

.agenda-container .agenda-timeline .timeline .timeline-description .timeline-contact-target .timeline-contact-data {
    cursor: pointer;
}
