.toolbarRight {
	display: flex;
    justify-content: flex-end;
    flex: 1;
}

.clients-grid-container {
    height: 100%;
    background-color: #f5f5f5;
    padding: .75rem;
    border-radius: 0.25rem;
    overflow-y: auto;
}

.clients-grid-container .clients-grid-container-flex {
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;
}

.clients-grid-container .clients-grid-container-flex .skeleton-contact-card {
    flex: 1 1 calc(20% - .6rem);
    max-width: calc(20% - .6rem);
    min-width: 20rem;
    height: 10rem;
}

.clients-table-channels:hover {
    cursor: pointer;
}

.client-grid-search-text-field {
    width: 20rem;
    position: absolute;
    top: 8rem;
}

.client-grid-search-text-field .MuiInputBase-input {
    height: 1.5rem;
    padding: 0.5rem;
    line-height: 1.5rem;
}

