.flexbox{
    display:flex;
    gap:1rem;
}

.defaultSpacing{
    display:flex;
    flex-direction: column;
    gap:1rem
}

.relativeBox{
    position:relative;
    margin-bottom: 1rem
}

.marginUnset{
    margin: unset
}
