.call-columns {
	border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    display:flex;
    flex-direction:column
}
.fit-tab.v2{
    max-height: 78.4svh;
}

/* .fit-tab.v2.auto{
    overflow:auto
} */

.fit-tab.v2.nomaxheight{
    max-height: unset
}

.fit-tab{
    height: 100%;
}

.column-header {
	display: grid;
	padding: 5px;
    width: 100%;
    height: 35px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #21446c;
    z-index: 1;
    border-radius: 5px 5px 0 0;
}

.column-title {
	display: flex;
    place-self: center;
    align-items: center;
	color: white;
    font-weight: bold;
}

.queue-length {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 100%;
    color: #21446c;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    text-align-last: center;
}

.length-small {
    font-size: 12px;
    padding: 1px;
}

.length-big {
    font-size: 9px;
    padding: 4px 1px 4px 0;
}

.calls-list {
    margin-top: 5vh;
    display: contents;
}

.filter-chats {
    display: flex;
}

.finished-chats-filter {
    padding-bottom: 0px;
    border-radius: 0px;
}

.finished-chats-filter form {
    width:100%;
    height: 3.5rem;
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #e1e1e1;
}

.popover-filter > .MuiPopover-paper {
    padding: 10px;
    width: 100%;
    max-width:20rem
}

.filters-buttons {
    text-align: center;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: -5px;
}

.filters-body, .filter-switch {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px;
}

.date-start-time {
    margin-top: 15px;
}

.date-end-time {
    margin-top: 10px;
}

.filters-action {
    margin-top: 10px;
    text-align: end;
}

.button-discard {
    margin-right: 5px;
}

.switch-item {
    margin-bottom: -10px;
}

.switchs-filters {
    display: grid;
    padding: 0px 0px 3px 17px;
}

.title-filters {
    font-size: 13px;
    text-align: start;
    color: #21446c;
    font-weight: bold;
}

.buttons-group {
    margin-top: 5px;
    margin-bottom: 3px;
}

.tab {
    min-width: 25%;
    color: #0000008a;
}

.filter-icon {
    color: #757575;
}

.filter-icon:hover {
    color: #21446c;
}

.group-item {
    padding-left: 5rem;
    padding-right: 5rem;
    justify-content: center;
    height: 3rem;
}

.group-item > label {
    width: 10rem;
}

.group-item > label span{
    min-width: max-content;
}

.manage-calls-list {
    max-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.manage-calls-queues {
    padding-top: 5rem;
    padding-bottom: 1rem;
}

.is-filtering {
    margin-top: 10px;
    margin-left: 10px;
    color: white;
    font-weight: bold;
    background-color: #21446c;
}

.is-filtering > path {
    color: #ffffff66;
}
