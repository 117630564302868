.main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background-color: #f2f4f6;
}

.main-container .MuiTabs-indicator {
	display: none;
}

.main-container .main-container-content {
	border-top: 1px solid #e2e2e2;
	margin-top: -1px;
	height: 100%;
	background-color: white;
	min-height: 1rem;
}

.main-container .main-container-content .hide-assistant-close-btn .close-icon {
	display: none;
}

.hide-assistant-close-btn{
	height: 100%;
	position: relative
}

.main-container .main-container-tabs button {
	min-height: 48px;
    padding: .5rem 1rem;
    font-size: 14px;
    color: gray;
    background-color: #f2f4f6;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border: 0;
    border-bottom: 1px solid #e2e2e2
}

.main-container .main-container-tabs button:first-child {
	border-left: 0 !important;
}

.main-container .main-container-tabs button.active {
	color: #21446c;
	background-color: white;
	border: 1px solid #e2e2e2;
	border-bottom: 0;
}
