.manage-calls-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.manage-calls-container .manage-calls-header {
  display: flex;
  gap: 0.5rem;
  margin-bottom: .5rem;
  flex-wrap: wrap;
}

.manage-calls-container .manage-calls-header .columns-switches {
  border-radius: 4px;
  background: #f2f4f6;
  padding: 0 1rem;
  height: 37px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.manage-calls-container .manage-calls-header .columns-switches label:first-child {
  margin-left: 0;
}

.manage-calls-container .manage-calls-content {
  display: flex;
  flex: 1;
  gap: 1rem;
  overflow-y: hidden;
}

.manage-calls-container .manage-calls-content > div.manage-calls-column {
  display: flex;
  flex-grow: 1;
  min-width: 25rem;
  max-width: 30rem;
}

.manage-calls-container .manage-calls-content > div.manage-calls-column > div:first-child {
  width: 100%;
}

.manage-calls-dashboard {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.1rem;
}

.manage-calls-flexbox {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  min-height: 1rem;
}

.manage-calls-single-box {
  flex: 1 1;
  box-shadow: 1px 1px 5px #d3d3d3;
  border-radius: 0.25rem;
  padding: 0.5rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.manage-calls-bigframe {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 85%;
}

.manage-calls-barGraph {
  height: 100%;
  text-align: -webkit-center;
}

.manage-teams-content {
  display:flex;
  flex-direction: column;
  flex: 0.3 1;
  min-width: 20rem;
  max-width: 25rem;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 5.2px #dddddd;
  border: 1px solid #cbcbcb;
}

.manage-teams-dashboard {
  gap: 0.5rem;
  flex: 1 1;
  border-radius: 0.25rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.manage-calls-divider {
  border-bottom: 1px solid #f3f3f3;
}
.manage-teams-team-buttom {
  border-bottom: 1px solid #f3f3f3;
  padding-right: 0rem;
  padding: 0rem 0rem 0rem 1rem;
}

.manage-teams-team-buttom > .MuiListItemText-root {
  margin: 0;
}

.manage-teams-team-text > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-teams-team-icons svg {
  font-size: 1.3em;
}

.manage-teams-team-icons {
  padding: 0.5rem;
}

.manage-teams-team-icons > div {
  padding: 0.15rem 0.5rem;
  border-radius: 0rem;
  display: flex;
  justify-content: space-between;
  gap: .25rem;
}

.manage-teams-team-icons .onchat {
  color: #1d25a3;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.manage-teams-team-icons .finished {
  color: #19a300;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.manage-teams-team-badge > span {
  right: -0.4rem;
}

.manage-teams-filter svg {
  font-size: 1.25rem;
}

.manage-teams-filter > button{
  width: 2.5rem;
  height: 2.5rem;
  align-self: flex-start;
  min-width: 2.5rem
}

.manage-teams-filter {
  display: flex;
  align-items: center;
}

.manage-team-searchbar {
  padding: 0rem 0.5rem;
  background: #efefef;
}

.manage-teams-menu-title {
  font-size: 1em;
  font-weight: bold;
  color: #21446c;
  padding: 0.5rem;
}

.manage-teams-dashboard-box {
  flex: 1;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 5.2px #dddddd;
  border: 1px solid #cbcbcb;
}

.manage-teams-dashboard-container {
  height: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  display: flex;
  flex: 1;
  min-height: 1rem;
}

.manage-teams-dashboard-title {
  padding: 0.5rem;
  border-bottom: 1px solid #cbcbcb;
  width: 100%;
  text-align: center;
  font-weight: bold;
  background: #21446c;
  color: white;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-teams-dashboard-value {
  flex: 1;
}

.manage-teams-dashboard-value {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 2em;
}

.manage-teams-dashboard-box.graph > div {
  width: 100%;
  padding: 0.5rem !important;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden
}

.manage-teams-dashboard-box.graph > div > h6 {
  align-self: baseline;
}

.manage-teams-dashboard-box.graph > div > svg {
  width: unset;
}

.manage-calls-header .filter-chats .MuiPaper-root{
  height: 37px
}

.manage-teams-dashboard-list{
  display: flex;
    width: 100%;
    padding: .5rem;
    flex-direction: column;
    gap: .25rem;
    overflow: auto;
    align-items: flex-start;
    flex: 1
}

.manage-teams-dashboard-listitem {
  width: 100%;
  display: flex;
  align-items: center;
  gap:.25rem
}

.label-item.withpadding{
  padding:.25rem;
  margin: 0
}

.manage-teams-space-icons{
  display:flex;
  flex:1;
  justify-content: flex-end;
}

.manage-teams-filter-popover{
  display:flex;
  flex-direction: column;
  gap:.5rem
}

.popover-filter.managercalls > .MuiPopover-paper{
  max-width: 35rem;
}