.clients-card {
    flex: 1 1 calc(20% - .6rem);
    max-width: calc(20% - .6rem);
    min-width: 20rem;
    height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: .25rem;
    box-shadow: none;
}

.clients-card:hover {
    border: 1px solid #808080;
    cursor: pointer;
}

.clients-card .clients-card-content {
    padding: 0.75rem 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
}

.clients-card .clients-card-content svg {
    font-size: 1rem;
}

.clients-card .clients-card-content .MuiTypography-body2 {
    font-size: .9rem;
}

.clients-card .clients-card-content .header {
    display: flex;
    gap: 0.25rem;
}

.clients-card .clients-card-content .header .MuiTypography-h6 {
    font-size: 1rem;
}

.clients-card .clients-card-content .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 0.25rem;
}

.clients-card .clients-card-content .footer {
    display: flex;
}

.clients-card .clients-card-content .footer .client-group-item {
    padding: 0.25rem;
    max-width: 5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clients-card .clients-card-content .actions {
    position: absolute;
    right: 0.5rem;
}

.clients-card .clients-card-content .actions svg {
    font-size: 1.25rem;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 15rem;
}
