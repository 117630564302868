.btnStart {
    align-items: flex-start;
}

.btnInactive.btnStart:hover {
    text-decoration: none;
    background-color: rgba(33, 68, 108, 0.04);
    color: #21446C;
}

.btnInactive svg,
.btnInactive {
    background-color: transparent;
    color: #21446C;
    fill: #21446C;
}

.MuiButtonBase-root.MuiToggleButton-root {
    color: #21446c;
}

.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
    background-color: #21446c;
    color: white;
}
